import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SubscriptionPage.css';

const SubscriptionPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const getUserEmail = () => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          return parsedUser.email;
        } catch (error) {
          console.error("Error parsing user data:", error);
          return null;
        }
      }
      return null;
    };

    const customerEmail = getUserEmail();

    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    const pricingTableContainer = document.getElementById('stripe-pricing');
    pricingTableContainer.innerHTML = `
      <stripe-pricing-table
        pricing-table-id="prctbl_1PsbhBP6Dhc1Dsl5a0kMRLBG"
        publishable-key="pk_live_51PrXCQP6Dhc1Dsl5I2En9avdBnh3wvaMbJ6DKQXdIzLAlyfOAZOo2jGoRkOOi05c2J7WMmG3ER9TmC9UeT81PHso00FZ8KRNzR"
        ${customerEmail ? `customer-email="${customerEmail}"` : ""}
      ></stripe-pricing-table>
    `;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="subscription-container">
      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button className="nav-button" onClick={() => navigate(-1)}>← Back</button>
        <Link to="/" className="nav-button">🏠 Home</Link>
      </div>

      <h1 className="subscription-header">Choose Your Subscription Plan</h1>

      <div className="subscription-content">
        {/* Subscription Details on the Left */}
        <div className="subscription-details">
          <div className="plan">
            <h2>✨ Professional Plan</h2>
            <p>Perfect for users who want to collaborate, edit, and enhance itineraries with powerful tools.</p>
            <ul>
              <li>🔹 <strong>Modify & Share Itineraries</strong></li>
              <li>🔹 <strong>Generate Vacation Offers</strong> (🚧 Under Development)</li>
              <li>🔹 <strong>Connect via Live Chat</strong> (🚧 Under Development)</li>
              <li>🔹 Exclusive Access to Advanced Features</li>
              <li>🔹 Personalized Recommendations & Insights</li>
            </ul>
          </div>

          <div className="plan">
            <h2>🏢 Enterprise Plan</h2>
            <p>A full-featured <strong>white-label</strong> version of Ditinerary for travel agencies, concierge services, and businesses.</p>
            <ul>
              <li>🚀 <strong>Full White-Label Customization</strong></li>
              <li>📊 <strong>Quote Generation Tool</strong> (🚧 Under Development)</li>
              <li>💬 <strong>Dedicated Live Chat Support</strong></li>
              <li>🌍 <strong>Custom URLs & Branding</strong></li>
              <li>🔧 <strong>Ability to Request Custom Features</strong></li>
            </ul>
          </div>
        </div>

        {/* Pricing Table on the Right */}
        <div id="stripe-pricing" className="stripe-pricing-wrapper"></div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
