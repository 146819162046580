import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { dbFQDN, basePath, appVersion } from "../config";
import "./CategoryItem.css";

const CategoryItem = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const localStorageKey = `itemData_${appVersion}`;
  const category = location.pathname.split("/")[1].toLowerCase();

  // Navigation function for category button selection
  const handleButtonSelect = (categoryID) => {
    navigate(`/${categoryID}`);
  };

  // Category buttons data (Itinerary added)
  const category_data = [
    { id: "itinerary", name: "Itinerary", icon: "fas fa-clipboard-list" },
    { id: "park", name: "Park", icon: "fas fa-chess-rook" },
    { id: "ride", name: "Rides", icon: "fas fa-rocket" },
    { id: "dining", name: "Dining", icon: "fas fa-utensils" },
    { id: "resort", name: "Resort", icon: "fas fa-house" },
    { id: "room", name: "Room", icon: "fas fa-bed" },
    { id: "travel", name: "Travel", icon: "fas fa-bus" },
    { id: "extra", name: "Extras", icon: "fas fa-star" },
  ];

  // Helper: Generate an aria-label string from a key and its value.
  const generateAriaLabel = (key, value) => {
    const formattedKey = key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
    const valueText = Array.isArray(value) ? value.join(", ") : value;
    return `${formattedKey}: ${valueText}`;
  };

  // Check localStorage before fetching data
  const fetchItemData = useCallback(() => {
    setIsLoading(true);
    const storedData = JSON.parse(localStorage.getItem(localStorageKey)) || {};

    if (storedData[category]) {
      const selectedItem = storedData[category].find((item) => item.id === id);
      if (selectedItem) {
        setItemData(selectedItem);
        setIsLoading(false);
        return;
      }
    }

    // Otherwise, fetch and cache the full category data
    fetch(`${dbFQDN}/di/v1/${category}`)
      .then((response) => response.json())
      .then((data) => {
        storedData[category] = data;
        localStorage.setItem(localStorageKey, JSON.stringify(storedData));
        const selectedItem = data.find((item) => item.id === id);
        if (selectedItem) {
          setItemData(selectedItem);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setIsLoading(false));
  }, [category, id, localStorageKey]);

  useEffect(() => {
    fetchItemData();
  }, [fetchItemData]);

  if (isLoading) {
    return (
      <div className="item-page">
        <p>Loading...</p>
      </div>
    );
  }

  if (!itemData) {
    return (
      <div className="item-page">
        <p>No data available.</p>
      </div>
    );
  }

  // Exclude "links" and "category" from features (they’re shown separately)
  const featureKeys = Object.entries(itemData.data).filter(
    ([key, value]) => value && key !== "links" && key !== "category"
  );

  return (
    <div className="item-page">
      {/* Single Fixed Navigation (positioned with CSS) */}
      <nav className="fixed-nav">
        <div className="nav-container">
          <div className="nav-center">
            {category_data.map((btn, index) => (
              <div
                key={index}
                className="category-button-wrapper"
                onClick={() => handleButtonSelect(btn.id)}
              >
                <button className="category-button" aria-label={`${btn.name} Button`}>
                  <i className={btn.icon}></i>
                </button>
                <div className="category-name">{btn.name}</div>
              </div>
            ))}
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="item-card" aria-label={`${itemData.name}: ${itemData.comment}`}>
        <div className="item-header">
          <div className="item-image">
            <img
              src={`/c/${basePath}/${category}/${itemData.id}-360x360.webp`}
              alt={itemData.name}
            />
          </div>
          <div className="item-info">
            <h2>{itemData.name}</h2>
            <span className="item-category">{itemData.data.category}</span>
            <p>{itemData.comment}</p>
          </div>
        </div>

        <div className="item-details">
          <div className="details-grid">
            {featureKeys.map(([key, value]) => {
              const formattedKey = key
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase());
              const isMap = key.trim().toLowerCase() === "map";
              const ariaLabel = generateAriaLabel(key, value);
              return (
                <div key={key} className="detail-card" aria-label={ariaLabel}>
                  <h4>{formattedKey}</h4>
                  {isMap ? (
                    Array.isArray(value) ? (
                      <button
                        className="resort-map-button"
                        onClick={() =>
                          window.open(value[0], "_blank", "noopener noreferrer")
                        }
                        aria-label="Resort Map"
                      >
                        <i className="fas fa-map-marked-alt"></i>
                        <span>Resort Map</span>
                      </button>
                    ) : (
                      <button
                        className="resort-map-button"
                        onClick={() =>
                          window.open(value, "_blank", "noopener noreferrer")
                        }
                        aria-label="Resort Map"
                      >
                        <i className="fas fa-map-marked-alt"></i>
                        <span>Resort Map</span>
                      </button>
                    )
                  ) : Array.isArray(value) ? (
                    <ul className="details-list">
                      {value.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{value}</p>
                  )}
                </div>
              );
            })}
          </div>

          {itemData.data.links && (
            <div className="links-section" aria-label="Useful Links">
              <h4>Useful Links</h4>
              <ul>
                {itemData.data.links.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Link: ${link}`}
                    >
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;
