import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { dbFQDN, basePath, appVersion } from "../config";
import "./Category.css";

const Category = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // State hooks
  const [itemsData, setItemsData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [destination, setDestination] = useState("wdw"); // Default destination
  const [activeKeywords, setActiveKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [loadedItems, setLoadedItems] = useState({});
  const itemRefs = useRef({}); // To store refs for lazy-loaded items
  const localStorageKey = `itemData_${appVersion}`;

  // Define category data (with icons) – you can add or remove as desired.
  const categoryData = useMemo(
    () => [
      { id: "itinerary", name: "Itinerary", icon: "fas fa-clipboard-list" },
      { id: "park", name: "Park", icon: "fas fa-chess-rook" },
      { id: "ride", name: "Rides", icon: "fas fa-rocket" },
      { id: "dining", name: "Dining", icon: "fas fa-utensils" },
      { id: "resort", name: "Resort", icon: "fas fa-house" },
      { id: "room", name: "Rooms", icon: "fas fa-bed" },
      { id: "travel", name: "Travel", icon: "fas fa-bus" },
      { id: "extra", name: "Extras", icon: "fas fa-star" },
    ],
    []
  );

  // Get the current category ID from the URL – default to the first category if not found
  const currentPath = location.pathname.split("/")[1] || categoryData[0].id;
  const id = categoryData.find((category) => category.id === currentPath)?.id || categoryData[0].id;

  const handleItemSelect = (categoryID, catItem) =>
    navigate(`/${categoryID}/${catItem}`);

  // Navigation functions for the new nav bar
  const handleCategorySelect = useCallback(
    (parentId) => {
      setLoadedItems({});
      navigate(`/${parentId}`);
    },
    [navigate]
  );
  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
    console.log(`Selected destination: ${e.target.value}`);
  };

  // ----- Keyword filtering functions (unchanged) -----
  const filterItemsByAllKeywords = (data, activeKeywords) => {
    if (!data) return [];
    if (activeKeywords.length === 0) return data;
    return data.filter((item) => {
      const keywordsArray = Array.isArray(item.keywords)
        ? item.keywords.map((keyword) => keyword.toLowerCase().trim())
        : typeof item.keywords === "string"
        ? item.keywords.split(",").map((keyword) => keyword.toLowerCase().trim())
        : [];
      return activeKeywords.every((activeKeyword) =>
        keywordsArray.includes(activeKeyword.toLowerCase().trim())
      );
    });
  };

  const updateFilters = useCallback(
    (data) => {
      const filtered = filterItemsByAllKeywords(data, activeKeywords);
      setFilteredResults(filtered);

      // Generate keyword counts from the filtered results
      const keywordCounts = {};
      filtered.forEach((item) => {
        const kws =
          Array.isArray(item.keywords)
            ? item.keywords
            : typeof item.keywords === "string"
            ? item.keywords.split(",").map((kw) => kw.trim())
            : [];
        kws.forEach((keyword) => {
          if (keyword) {
            keywordCounts[keyword.toLowerCase()] =
              (keywordCounts[keyword.toLowerCase()] || 0) + 1;
          }
        });
      });
      const sortedKeywords = Object.entries(keywordCounts)
        .map(([keyword, count]) => ({ keyword, count }))
        .filter(({ keyword, count }) => !activeKeywords.includes(keyword) && count !== filtered.length)
        .sort((a, b) => b.count - a.count);
      setKeywords(sortedKeywords);
      setFilteredKeywords(sortedKeywords);
    },
    [activeKeywords]
  );

  const handleKeywordFilter = (event) => {
    const value = event.target.value.trim().toLowerCase();
    setKeywordFilter(value);
    if (value) {
      const updatedFilteredKeywords = keywords.filter(
        ({ keyword }) =>
          keyword.toLowerCase().startsWith(value) && !activeKeywords.includes(keyword)
      );
      setFilteredKeywords(updatedFilteredKeywords);
    } else {
      setFilteredKeywords(
        keywords.filter(
          ({ keyword, count }) =>
            !activeKeywords.includes(keyword.toLowerCase()) && count !== filteredResults.length
        )
      );
    }
  };

  const handleKeywordSave = (event) => {
    if (event.key === "," || event.key === "Enter") {
      const trimmedKeyword = keywordFilter.trim().replace(/,$/, "").toLowerCase();
      if (trimmedKeyword && !activeKeywords.includes(trimmedKeyword)) {
        const newActiveKeywords = [...activeKeywords, trimmedKeyword];
        setActiveKeywords(newActiveKeywords);
        const filtered = filterItemsByAllKeywords(itemsData[id], newActiveKeywords);
        setFilteredResults(filtered);
        const updatedFilteredKeywords = filtered
          .flatMap((item) => (item.keywords || "").split(",").map((kw) => kw.trim()))
          .filter(
            (kw) =>
              !newActiveKeywords.includes(kw.toLowerCase()) &&
              keywords.some(({ keyword }) => keyword === kw)
          );
        setFilteredKeywords(
          [...new Set(updatedFilteredKeywords)].map((kw) => ({
            keyword: kw,
            count: filtered.filter((item) => item.keywords?.includes(kw)).length,
          }))
        );
      }
      setKeywordFilter("");
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    const newActiveKeywords = activeKeywords.filter(
      (keyword) => keyword !== keywordToRemove
    );
    setActiveKeywords(newActiveKeywords);
    const filtered = filterItemsByAllKeywords(itemsData[id], newActiveKeywords);
    setFilteredResults(filtered);
    const updatedFilteredKeywords = filtered
      .flatMap((item) => {
        const kws =
          Array.isArray(item.keywords)
            ? item.keywords
            : typeof item.keywords === "string"
            ? item.keywords.split(",").map((kw) => kw.trim())
            : [];
        return kws;
      })
      .filter(
        (kw) =>
          !newActiveKeywords.includes(kw.toLowerCase()) &&
          keywords.some(({ keyword }) => keyword === kw)
      );
    setFilteredKeywords(
      [...new Set(updatedFilteredKeywords)].map((kw) => ({
        keyword: kw,
        count: filtered.filter((item) =>
          Array.isArray(item.keywords)
            ? item.keywords.includes(kw)
            : item.keywords.includes(kw)
        ).length,
      }))
    );
  };

  const handleAddKeyword = (keyword) => {
    if (!activeKeywords.includes(keyword)) {
      const newActiveKeywords = [...activeKeywords, keyword.toLowerCase()];
      setActiveKeywords(newActiveKeywords);
      const filtered = filterItemsByAllKeywords(itemsData[id], newActiveKeywords);
      setFilteredResults(filtered);
      const updatedFilteredKeywords = filtered
        .flatMap((item) => {
          const kws =
            Array.isArray(item.keywords)
              ? item.keywords
              : typeof item.keywords === "string"
              ? item.keywords.split(",").map((kw) => kw.trim())
              : [];
          return kws;
        })
        .filter(
          (kw) =>
            !newActiveKeywords.includes(kw.toLowerCase()) &&
            keywords.some(({ keyword }) => keyword === kw)
        );
      setFilteredKeywords(
        [...new Set(updatedFilteredKeywords)].map((kw) => ({
          keyword: kw,
          count: filtered.filter((item) =>
            Array.isArray(item.keywords)
              ? item.keywords.includes(kw)
              : item.keywords.includes(kw)
          ).length,
        }))
      );
      setKeywordFilter("");
    }
  };

  // ----- Lazy load images via IntersectionObserver -----
  useEffect(() => {
    const inViewStates = {};
    const observers = Object.keys(itemRefs.current).map((itemId) => {
      const ref = itemRefs.current[itemId]?.current;
      if (ref) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting && !inViewStates[itemId]) {
              inViewStates[itemId] = true;
              setLoadedItems((prev) => ({ ...prev, [itemId]: true }));
              observer.unobserve(entry.target);
            }
          },
          { threshold: 0.1, root: null }
        );
        observer.observe(ref);
        return observer;
      }
      return null;
    });
    return () => observers.forEach((observer) => observer && observer.disconnect());
  }, [filteredResults]);

  // ----- Fetch and update data -----
  useEffect(() => {
    if (itemsData[id]) {
      updateFilters(itemsData[id]);
    } else {
      const storedData = JSON.parse(localStorage.getItem(localStorageKey)) || {};
      if (storedData[id]) {
        setItemsData((prev) => ({ ...prev, [id]: storedData[id] }));
        updateFilters(storedData[id]);
      } else {
        setIsLoading(true);
        fetch(`${dbFQDN}/di/v1/${id}`)
          .then((response) => response.json())
          .then((data) => {
            const transformedData = data.map((item) => ({
              ...item,
              keywords: Array.isArray(item.data?.keywords)
                ? item.data.keywords.join(",")
                : typeof item.data?.keywords === "string"
                ? item.data.keywords
                : "",
            }));
            setItemsData((prev) => ({ ...prev, [id]: transformedData }));
            localStorage.setItem(
              localStorageKey,
              JSON.stringify({ ...storedData, [id]: transformedData })
            );
            setActiveKeywords([]);
            updateFilters(transformedData);
          })
          .catch((error) => console.error("Error fetching data:", error))
          .finally(() => setIsLoading(false));
      }
    }
  }, [id, itemsData, localStorageKey, updateFilters]);

  return (
    <div className="category-page">
      {/* ===== Fixed Navigation Bar ===== */}
      <nav className="fixed-nav">
        <div className="nav-container">
          <div className="nav-center">
            {categoryData.map((btn) => (
              <div
                key={btn.id}
                className="category-button-wrapper"
                onClick={() => handleCategorySelect(btn.id)}
              >
                <button className="category-button" aria-label={`${btn.name} Button`}>
                  <i className={btn.icon}></i>
                </button>
                <div className="category-name">{btn.name}</div>
              </div>
            ))}
          </div>
          <div className="nav-right">
            <div className="nav-destination">
              <label htmlFor="destination-select">Destination:</label>
              🚧
              <select
                id="destination-select"
                value={destination}
                onChange={handleDestinationChange}
              >
                <option value="wdw">Walt Disney World</option>
                <option value="dlr">Disneyland Resort</option>
                <option value="uo">Universal Orlando</option>
              </select>
              🚧
            </div>
          </div>
        </div>
      </nav>

      {/* ===== Content Layout ===== */}
      <div className="content-layout">
        {/* --- Filter Bar --- */}
        <div className="filter-bar">
          <input
            type="text"
            placeholder="Search or Add Keywords"
            className="filter-input"
            value={keywordFilter}
            onChange={handleKeywordFilter}
            onKeyDown={handleKeywordSave}
          />
          <div className="active-keywords">
            {activeKeywords.map((activeKeyword, index) => (
              <div key={index} className="active-keyword">
                {activeKeyword}
                <button
                  className="remove-keyword"
                  onClick={() => handleRemoveKeyword(activeKeyword)}
                >
                  x
                </button>
              </div>
            ))}
          </div>
          <div className="keyword-list">
            {filteredKeywords.map(({ keyword, count }) => (
              <div
                key={keyword}
                className="keyword-item"
                onClick={() => handleAddKeyword(keyword)}
              >
                {keyword}
              </div>
            ))}
          </div>
        </div>

        {/* --- Main Content --- */}
        <div className="main-content">
          {isLoading ? (
            <p className="loading-text">Loading...</p>
          ) : (
            filteredResults.map((item) => {
              if (!itemRefs.current[item.id]) {
                itemRefs.current[item.id] = React.createRef();
              }
              return (
                <div
                  key={item.id}
                  className="content-card"
                  ref={itemRefs.current[item.id]}
                  onClick={() => handleItemSelect(id, item.id)}
                >
                  <div className="poster">
                    {loadedItems[item.id] && (
                      <img
                        src={`/c/${basePath}/${id}/${item.id || "default"}-360x360.webp`}
                        alt={item.comment || `${id} slot image`}
                        className="poster-image"
                      />
                    )}
                    <div className="poster-overlay">
                      <h3 className="poster-title">{item.data?.title || item.name}</h3>
                      <p className="poster-description">
                        {item.data?.seoDescription || item.comment}
                      </p>
                      <div className="poster-learn-more">
                        <button>Learn More</button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
