import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './About.css';

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="about-container">
      <div className="about-content">
        <div className="navigation-buttons">
          <button className="nav-button" onClick={() => navigate(-1)}>← Back</button>
          <Link to="/" className="nav-button">🏠 Home</Link>
        </div>

        <h1>About Ditinerary</h1>

        <p>
          <strong>Ditinerary</strong> is your all-in-one platform for planning, managing, and sharing your dream Disney vacations. 
          Whether you’re a <strong>casual traveler</strong> or a <strong>professional travel planner</strong>, our tools help streamline your trip planning experience.
        </p>

        <h2>🌟 Our Mission</h2>
        <p>
          We aim to <strong>revolutionize</strong> Disney vacation planning by offering an <strong>intuitive, modern, and feature-rich</strong> platform. 
          Say goodbye to spreadsheets and fragmented planning tools—Ditinerary brings everything into <strong>one place</strong>.
        </p>

        <h2>✨ Why Choose Ditinerary?</h2>
        <ul>
          <li>🎟 <strong>Effortless Itinerary Management</strong> – Plan every detail of your trip with ease.</li>
          <li>🚀 <strong>Professional-Level Features</strong> – For users who want advanced customization.</li>
          <li>💬 <strong>Real-Time Collaboration</strong> – Edit and share plans with friends, family, or clients.</li>
          <li>📍 <strong>Custom Travel Offers</strong> – (🚧 Coming Soon) Generate exclusive vacation packages.</li>
          <li>📢 <strong>Live Chat Support</strong> – (🚧 Coming Soon) Instant assistance for planning help.</li>
        </ul>

        <h2>💼 Who Can Benefit?</h2>
        <p><strong>Ditinerary is built for:</strong></p>
        <ul>
          <li>🌟 Disney fans planning their <strong>ultimate dream trip</strong>.</li>
          <li>🧳 Travel professionals who need <strong>custom itineraries for clients</strong>.</li>
          <li>📍 Tour guides and agencies looking for <strong>white-label solutions</strong>.</li>
        </ul>

        <h2>📞 Need Help? Visit Our Support Page</h2>
        <p>
          If you have any <strong>questions, feedback, or technical issues</strong>, we’re here to help.  
          Visit our <strong>Support Center</strong> or email us at: 
          <a href="mailto:support@ditinerary.com" className="email-link">support@ditinerary.com</a>
        </p>

        <div className="support-links">
          <a href="mailto:support@ditinerary.com" className="email-button">Email Support</a>
        </div>
      </div>
    </div>
  );
};

export default About;
