import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Login.css';
import { dbFQDN } from '../config';

const clientId = '857486561791-u4g9rro1juf4s8rn2nr6npnve11qck26.apps.googleusercontent.com';

const Login = () => {
  const navigate = useNavigate();

  const onFacebookSuccess = (response) => {
    if (!response.accessToken) {
      console.error('Facebook login failed');
      return;
    }

    fetch(`${dbFQDN}/auth/facebook/callback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: response.accessToken }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        Cookies.set('token', data.access_token, { expires: 30, secure: true, sameSite: 'None', domain: '.ditinerary.com' });
        navigate('/');
      })
      .catch((error) => console.error('Error:', error));
  };

  const onGoogleSuccess = (credentialResponse) => {
    if (!credentialResponse.credential) {
      console.error('Google login failed');
      return;
    }

    fetch(`${dbFQDN}/auth/google/callback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: credentialResponse.credential }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        Cookies.set('token', data.access_token, { expires: 30, secure: true, sameSite: 'None', domain: '.ditinerary.com' });
        navigate('/');
      })
      .catch((error) => console.error('Error:', error));
  };

  const onError = () => {
    console.log('Login Failed');
    alert('Failed to login.');
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="login-container">
        <div className="login-content">
          <h1 className="login-title">Welcome to Ditinerary</h1>
          <p className="login-subtitle">Please log in using one of the methods below to access your itineraries.</p>
          <div className="login-button">
            <FacebookLogin
              appId="1198776918112409"
              fields="name,email,picture"
              callback={onFacebookSuccess}
            />
            <GoogleLogin onSuccess={onGoogleSuccess} onError={onError} />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
